@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

body {
  margin: 0;
  background-color: #232323 !important;
  color: #F1F2F3 !important;
  font-family: Roboto;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input::placeholder {
  color: "#FFF" !important;
  opacity: 1;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  font-family: ubuntu;
}

h3 {
  font-family: ubuntu;
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

Button {
  border-style: solid
}

.header {
  font-size: 24px;
}

.navbar-menu {
  height:100vh;
  background-color:"white";
  box-shadow: 1px 1px 7px rgba(0,0,0,0.15);
  transition: all 0.5s ease;
  
}
.burger{
  display:flex;
  justify-content: flex-end;
}
.burger img {
  padding: 25px 8px 0px 0px;
  cursor: pointer;
}
.navbar__list {
  list-style-type: none;
  
}
.navbar__li {
  display: inline-block;
  padding:0px 0px 0px 12px;
  position: relative;
  top:2.5px;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 38px;
  color: #000000;
  font-family: "Lato";
  font-weight: 400;
}
.navbar__li-box:first-child {
  margin-top:53px;
}
.navbar__li-box {
  height: 43px;
  margin-top:15px;
}
.navbar__li-box:hover {
  border-left: 7px solid #58b5fc;
  cursor: pointer;
}

.transcript {
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  margin: 10px;
}

.sidenav {
  background-color: #515A61;
}

.sidenav a {
  color: #fff;
  background-color: #515A61;
}

.modalBase {
  position: absolute;
  background-color: #1B2024;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  border-radius: 10px;
}

button.editorTab {
  color: #fff !important;
  font-family: ubuntu !important;
  padding-right: 10px;
}

.MuiSvgIcon-colorPrimary {
  color: #fff !important
}

.MuiButton-textPrimary {
  color: #fff !important
}

.MuiSelect-root {
  color: #fff !important
}

.MuiSelect-icon {
  color: #fff !important
}

p.MuiFormHelperText-root {
  color: #fff !important
}

.sidenav-icon{
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(308deg) brightness(104%) contrast(104%);
  /*transform: scale(0.8)*/
}

.fileUploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2;
  border-radius: 2;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out
}

.fileList {
  background-color: #fff;
  color: #000;
}

.fileList li {
  padding-top: 10px
}
